import React from "react";
import { createContext, useState, useEffect, useCallback } from "react";
import { TOKEN_SECRECT } from "../shared/constants/auth-types";
import {
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue,
} from "../shared/services/localStorage.service";
import { UsersPermissionsUserEntity } from "../generated/graphql";

import axios, { InternalAxiosRequestConfig } from "axios";
export interface IUserContext {
  jwt?: string;
  user?: UsersPermissionsUserEntity;
  setUser(user?: UsersPermissionsUserEntity | undefined): void;
  setJwt(jwt?: string): void;
  loadingUser: boolean;
  expirationTime?: any;
  stayConnected: boolean;
  setStayConnected: (boolean: boolean) => void;
  logout: () => void;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }: { children: any }) => {
  const [user, setUser] = useState<UsersPermissionsUserEntity | undefined>(
    undefined,
  );
  const [tokenExpirationDate, setTokenExpirationDate] = useState<Date>();
  const [stayConnected, setStayConnected] = useState<boolean>(true);

  const [jwt, setJwt] = useState<string | undefined>("");
  const [loadingUser, setLoadingUser] = useState(true);

  let logoutTimer: any;

  useEffect(() => {
    try {
      const data = getLocalStorageValue(TOKEN_SECRECT);

      if (!!data) {
        const userData = JSON.parse(data) as IUserContext;

        if (window.location.pathname === "/") {
          window.location.href = "/home";
        }

        axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
          config.headers!.authorization = `Bearer ${userData.jwt}`;
          return config;
        });

        userData.user && setUser({ ...userData.user });
        userData.jwt && setJwt(userData.jwt);
        userData.expirationTime && setStayConnected(false);
      }

      if (!data) {
        if (window.location.pathname === "/home") {
          window.location.href = "/";
        }
      }
    } catch (error) {
      console.log(error);
    }

    setLoadingUser(false);
  }, []);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        if (response?.status === 401) {
          removeLocalStorageValue(TOKEN_SECRECT);
          logout();
        }

        return Promise.resolve(response);
      },
      (error) => {
        if (error.response?.status === 401) {
          console.log(error);
          logout();
        }
        return Promise.reject(error);
      },
    );
  }, []);

  const logout = useCallback(() => {
    removeLocalStorageValue(TOKEN_SECRECT);
    setUser(undefined);
    window.location.reload();
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(
      getLocalStorageValue(TOKEN_SECRECT)!,
    ) as IUserContext;
    if (
      storedData &&
      storedData.expirationTime &&
      storedData.jwt &&
      new Date(storedData.expirationTime) > new Date()
    ) {
      setTokenExpirationDate(new Date(storedData.expirationTime));
    }
  }, [logout]);

  useEffect(() => {
    if (tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate!.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [logout, tokenExpirationDate]);

  return (
    <UserContext.Provider
      value={{
        user,
        jwt,
        setUser,
        setJwt,
        loadingUser,
        stayConnected,
        setStayConnected,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const _getFromLocalstorage = <T,>(key: string = TOKEN_SECRECT) => {
  const dataLocalStorage = getLocalStorageValue(key);
  if (!!dataLocalStorage) {
    const data = JSON.parse(dataLocalStorage) as T;
    return data;
  }

  return undefined;
};

export const getUserContext = () => {
  const data = _getFromLocalstorage<IUserContext>();

  if (!data) {
    return undefined;
  }

  return data.user;
};

export const getUserWithJwtContext = () => {
  const data = _getFromLocalstorage<IUserContext>();

  if (!data) {
    return { user: undefined, jwt: undefined };
  }

  return data;
};

export const updateUserContext = (user: UsersPermissionsUserEntity) => {
  const _ = getUserWithJwtContext();
  _!.user = { ...user };
  setLocalStorageValue(TOKEN_SECRECT, JSON.stringify({ ..._ }));
};

export const upsertUserContext = (data: {
  user: UsersPermissionsUserEntity | undefined;
  jwt: string;
  expirationTime?: any;
}) => {
  const userLocal = _getFromLocalstorage<IUserContext>(TOKEN_SECRECT);

  if (!!userLocal) {
    removeLocalStorageValue(TOKEN_SECRECT);
  }

  setLocalStorageValue(TOKEN_SECRECT, JSON.stringify(data));
};
